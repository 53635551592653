// extracted by mini-css-extract-plugin
export var business = "index-module--business--Y4orF";
export var businessContent = "index-module--business-content---tVO3";
export var businessGrid = "index-module--business-grid--uNfK1";
export var businessItem = "index-module--business-item--jMONV";
export var heroUnit = "index-module--hero-unit--CjpLl";
export var image = "index-module--image--bScvh";
export var news = "index-module--news--ev8S3";
export var newsBody = "index-module--news-body--SXH4j";
export var newsDate = "index-module--news-date--E-p5b";
export var newsGrid = "index-module--news-grid--htk6-";
export var newsImage = "index-module--news-image--51Ovh";
export var newsItem = "index-module--news-item--FKrIh";
export var newsLinks = "index-module--news-links--agBHa";
export var newsTitle = "index-module--news-title--jTy5m";
export var rainbow = "index-module--rainbow--zOhpe";
export var slogan = "index-module--slogan--bc-nt";
export var title = "index-module--title--1jLlN";
export var variant1 = "index-module--variant1--Mi32i";
export var variant2 = "index-module--variant2--uh88P";
export var variant3 = "index-module--variant3--AqPXB";
export var works = "index-module--works--9BYEE";
export var worksGrid = "index-module--works-grid--2Ijse";
export var worksItem = "index-module--works-item--kFpg1";