import React from "react"
import { graphql } from "gatsby";
import { FiMapPin } from "react-icons/fi";
import {StaticImage} from "gatsby-plugin-image";

import Layout from "../containers/Layout";
import * as css from "../sass/index.module.scss";
import Seo from "../containers/Seo";

const IndexPage = ({data}) => {
  return (
    <Layout>
      <section className={css.heroUnit}>
        <div className={css.slogan}>
          <h1>Noop, LLC.</h1>
          <h2>“超”高速システム開発<br />が得意な<br />ハッカーがいる会社</h2>
          <address><FiMapPin /> Toyama, Japan.</address>
        </div>
      </section>

      <section className={css.news}>
        <h1>お知らせ</h1>

        <div className={css.newsGrid}>
          <div className={css.newsItem}>
            <div className={css.newsImage}>
              <StaticImage src={"../images/mamoru-square.png"} alt={"無料アプリ「まもる」"} />
            </div>
            <div className={css.newsBody}>
              <div className={css.newsDate}>2022.09.26</div>
              <div className={css.newsTitle}>送迎バス置き去り防止のための運行管理アプリ「まもる」をリリースしました</div>
              <div className={css.newsLinks}>
                <a className="btn" href="https://mamoru-bus.com">バス運行管理アプリ「まもる」ホームページ</a>
                <a className="btn" href="https://prtimes.jp/main/html/rd/p/000000006.000062334.html">プレスリリース (PRTIMES)</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={css.business}>
        <h1>事業内容</h1>

        <div className={css.businessGrid}>
          <div className={`${css.businessItem} ${css.variant1}`}>
            <StaticImage className={css.image} src={"../images/business1.png"} alt={"事業内容: オートメーション"} />
            <div className={css.businessContent}>
              <h2 className={css.title}>オートメーション</h2>
              <p>
                単純な作業をボタン1つで、面倒な入力をQRコードで、など方法はさまざまです。<br />
                クラウド・アプリ・AI・各種デバイスといったありとあらゆる手段を使って実現します。<br />
                <br />
                どの方法を選択しても、業務のプロであるお客様との連携が欠かせません。<br />
                必要とあれば実際に業務を体験し、最適な答えを探し出します。
              </p>
            </div>

          </div>

          <div className={`${css.businessItem} ${css.variant2}`}>
            <StaticImage className={css.image} src={"../images/business2.png"} alt={"事業内容: クラウド導入"} />
            <div className={css.businessContent}>
              <h2 className={css.title}>クラウド導入</h2>
              <p>
                既存システムのサーバレス化を進めることで、驚くほどのコスト削減につながることがあります。<br />
                AWS Lambda、Firebase Functions、Google Cloud Tasks<br />
                これらを活用し、安全かつ低コストなシステム運用をお手伝いします。<br />
                業務システムのバッチ処理からモバイルアプリのバックエンドまで幅広く対応可能。
              </p>
            </div>
          </div>

          <div className={`${css.businessItem} ${css.variant3}`}>
            <StaticImage className={css.image} src={"../images/business3.png"} alt={"事業内容: システム開発"} />
            <div className={css.businessContent}>
              <h2 className={css.title}>システム開発</h2>
              <p>
                iPhoneアプリからデスクトップの業務システムまで、世の中にある技術はだいたい扱えます。<br />
                ECサイトから工場のハードウェアまでつなげる技術(実績あり)、NDIやWebRTCによるリアルタイム映像共有技術(実績あり)、ゲーム以外なら何でも作れます。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={css.works}>
        <h1>開発事例</h1>

        <div className={css.worksGrid}>
          <div className={css.worksItem}>
            <h2><span>CASE.1</span> プライベートSNSサーバー構築自動化</h2>
            <p>
              プライベートMastodonインスタンスを構築したいというご依頼をいただき、かつ今後もサーバーを増やしていきたいとの希望をお持ちでした。
              弊社ではAWSのCloudFormationとHashiCorpのpackerを活用し、サーバー構築一式をすべて自動化しました。
              このIaasの恩恵を受けることにより、同じ環境を何度でも再現でき、テストも気軽に行えるようになります。
            </p>
          </div>
          <div className={css.worksItem}>
            <h2><span>CASE.2</span> Webデザインシミュレーターとガーメントプリンター製造管理</h2>
            <p>Web上でPCやスマホから、Tシャツ等アパレル製品のデザインができるシステムを構築しました。数あるデザインシミュレーターと異なり、ガーメントプリンター
            との連動、簡単なボタン操作だけで受注処理から製品の製造まですべてをカバーします。このシステムはパッケージとして導入が可能です。詳しくは<a href="https://uenoyama-digital.com/incidental/omakase/" target="_blank">上野山機構株式会社ホームページ </a>をご覧ください。</p>
          </div>
        </div>
      </section>


    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        description
        author
        twitter
        siteUrl
      }
    }
  }
`

export const Head = ({data}) => (
  <Seo data={data} />
);
